<template>
  <div id="mobile_features_box">
    <div class="features_btn_ul">
      <div
        v-for="(item, index) in FEATURES_BTN"
        :key="index"
        :class="['features_btn_li', index === activeInd ? 'active' : '']"
        @click="changeSlide(index * 3)"
      >
        {{ item }}
      </div>
    </div>
    <div class="swiper-container outer" id="outer_swiper">
      <div class="swiper-wrapper outer">
        <div v-for="(outerItem, index) in FEATURES_DESC" :key="index" class="swiper-slide outer">
          <div class="feat_li_box">
            <div class="feat_li">
              <div class="text_box">
                <div class="li_title">{{ outerItem.title }}</div>
                <div class="li_p">{{ outerItem.text[0] }}</div>
              </div>
              <div class="img_box">
                <img :src="outerItem.imgUrl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import Swiper from 'swiper';
const FEATURES_BTN = ['工单管理', '客户管理', '设备管理', '备件管理', '自定义配置', 'I o t'];
import FEATURES_DESC_JSON from './featuresDesc.js';
const cloneJson = cloneDeep(FEATURES_DESC_JSON);
let FEATURES_DESC = [];
cloneJson.forEach(i => {
  i.children.forEach(item => {
    item.tabName = i.tabName;
    item.id = i.id;
    item.imgUrl = require(`@/assets/images/pcHome/${item.imgUrl}`);
  });
  FEATURES_DESC = [...FEATURES_DESC, ...i.children];
});

export default {
  data () {
    return {
      FEATURES_BTN,
      FEATURES_DESC,
      activeInd: 0,
      swiperObj: null,
    };
  },
  mounted () {
    this.$nextTick(() => {
      const _this = this;
      // eslint-disable-next-line no-new
      this.swiperObj = null;
      this.swiperObj = new Swiper('#outer_swiper', {
        slidesPerView: 'auto',
        spaceBetween: 15,
        on: {
          slideChange: function () {
            _this.activeInd = Math.floor(this.activeIndex / 3);
          },
        },
      });
    });
  },
  methods: {
    changeSlide (num) {
      this.swiperObj.slideTo(num);
    },
  },
  beforeDestroy () {
    this.swiperObj = null;
  },
};
</script>
<style lang="scss" scoped>
#mobile_features_box {
  .features_btn_ul {
    display: grid;
    justify-content: space-between;
    grid-template-columns: repeat(auto-fill, 71px);
    grid-gap: 12px;
    .features_btn_li {
      text-align: center;
      line-height: 36px;
      border-radius: 4px;
      border: 1px solid #7c848e;
      width: 71px;
      height: 36px;
      overflow: hidden;
      margin-bottom: 12px;
      margin-right: 12px;
      font-size: 12px;
      font-weight: 400;
      &.active {
        border-color: #0f8aff;
        color: #0f8aff;
        background: rgba(15, 138, 255, 0.1);
      }
    }
  }
  #outer_swiper {
    height: 277px;
    margin: auto;
    background: #fff;
    overflow: hidden;
    .swiper-wrapper.outer {
      display: flex;
      height: 100%;
      .swiper-slide.outer {
        display: flex;
        height: 100%;
        width: 80%;
        flex-shrink: 0;
        .feat_li_box {
          .feat_li {
            height: 277px;
            color: #333;
            overflow: hidden;
              position: relative;
            .text_box {
              padding: 16px 16px 0 16px;
              .li_title {
                font-weight: 600;
                line-height: 15px;
                font-size: 15px;
                margin-bottom: 14px;
              }
              .li_p {
                font-weight: 400;
                font-size: 12px;
                line-height: 21px;
              }
            }
            .img_box {
              margin: auto;
              width: 100%;
              height: 130px;
              position: absolute;
              bottom: 0;
              text-align: center;
              img {
                display: block;
                height: 100%;
                margin: auto;
              }
            }
          }
        }
      }
    }
  }
}
</style>
