<template>
  <div id="home_container">
    <HOMEHEADER
      ref="headerRef"
      :isPC="isPC"
      @goAnchor="goAnchor"
      :scrollOptical="495"
      sourceType="offical"
      @closeVideo="closeVideo"
      @change-tab="changeTab"
    />
    <div class="banner_box" id="home">
      <div class="banner_container">
        <div class="text_box">
          <div class="title">医修保</div>
          <div class="text">一站式saas服务平台，售后服务数字化解决方案。</div>
          <!--<el-button v-if="isPC" class="regis_btn" @click="$router.push({ name: 'REGISTER' })">立即试用</el-button>-->
        </div>
      </div>
    </div>
    <div class="video_box wow fadeInUp">
      <div class="video_container">
        <div class="video_left" ontouchmove="return false;">
          <video
            ref="videoRef"
            style="width: 100%; height: 100%; z-index: 1000"
            preload
            x5-video-player-type="h5"
            playsinline="true"
            x5-video-player-fullscreen="false"
            controls
            :src="videoUrl"
          ></video>
        </div>
        <div class="video_right">
          <h3>公司介绍</h3>
          <ul class="desc_ul">
            <li class="desc_li">医修保是国内领先的saas售后管理服务平台。</li>
            <li class="desc_li">
              以强大的产品能力及AI
              、IOT、AR等多项核心技术为基础，通过客户管理、工单管理、设备管理及多种自定义等一站式解决方案，推动设备售后管理流程智能化。
            </li>
            <li class="desc_li">帮助企业走向数字化转型新时代。</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="video_bottom_box wow fadeInUp">
      <div class="data_ul">
        <div class="data_item">
          <div class="num_p">
            <span class="num animate__animated animate__pulse animate__infinite">5000</span>
            家
          </div>
          <p class="desc">超5000家服务商的选择</p>
        </div>
        <div class="data_item">
          <div class="num_p">
            <span class="num animate__animated animate__pulse animate__infinite">10000</span>
            名
          </div>
          <div class="desc">超10000名工程师的平台</div>
        </div>
        <div class="data_item">
          <div class="num_p">
            <span class="num animate__animated animate__pulse animate__infinite">2000</span>
            家
          </div>
          <div class="desc">超2000家机构的信赖</div>
        </div>
        <div class="data_item">
          <div class="num_p">
            <span class="num animate__animated animate__pulse animate__infinite">100000</span>
            条
          </div>
          <div class="desc">超100000条知识库整合</div>
        </div>
      </div>
    </div>
    <div id="features" class="plan_box wow fadeInUp" data-wow-duration="2s">
      <div class="title_box">
        <h2>功能介绍</h2>
        <h4>开启医疗设备维修保养新体验</h4>
      </div>
      <featuresComponent v-if="isPC" :tabName="tabName" />
      <planTabsDesc v-else />
    </div>
    <div id="productSolutions" v-if="isPC" class="pain_point_box wow fadeInUp" data-wow-duration="2s">
      <div class="pain_container">
        <div class="title_box">
          <h2>产品解决方案</h2>
          <h4>开启医疗设备维修保养新体验</h4>
        </div>
        <div class="pain_content">
          <div class="pain_left">
            <div class="plan_point_ul">
              <div class="plan_point_title">
                <div class="title point">行业痛点</div>
                <div class="title plan">我们的解决方案</div>
              </div>
              <VueFaqAccordion
                class="faq_box"
                activeColor="#FFFFFF"
                fontColor="rgba(255,255,255,0.8000);"
                borderColor="transparent"
                questionProperty="titleFormat"
                :items="PLANDATA"
                v-slot="itemData"
                :initialQuestionIndex="activeIndex"
                @itemSelect="itemSelect"
              >
                <div class="format_text point_text">{{ itemData.point.text }}</div>
                <div class="format_text plan_text">{{ itemData.plan.text }}</div>
              </VueFaqAccordion>
            </div>
            <div class="plan_ul_bottom"></div>
          </div>
          <div class="pain_right">
            <transition-group name="bounce">
              <img
                v-show="index === activeIndex"
                v-for="(item, index) in painRightImgs"
                :key="item.imgUrl"
                :src="item.imgUrl"
              />
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <div id="productSolutions" v-if="!isPC" class="pain_point_box wow fadeInUp" data-wow-duration="2s">
      <div class="pain_container">
        <div class="title_box">
          <h2>产品解决方案</h2>
        </div>
        <div class="pain_content">
          <planPointComponent />
        </div>
      </div>
    </div>
    <div v-if="isPC" class="last_box wow fadeInUp">
      <div class="last_container">
        <div class="text_box">
          <p>
            <span class="name">医修保</span>
            提供专业的技术服务，致力于连同医院客户与维修商之间的沟通渠道，便捷轻松的报修方式，加强业务进度管控。
          </p>
          <!--<el-button class="try_out_btn" @click="$router.push({ name: 'REGISTER' })">立即试用</el-button>-->
        </div>
      </div>
    </div>
    <div v-if="!isPC" class="qrcode_box">
      <div class="text_container">
        <h2>医修保</h2>
        <p class="desc">
          提供专业的售后一站式解决方案及产品服务，智能化端到端服务管理，帮助厂家及服务商通过科技提升服务效率，以更好的工具管理团队，以更强的能力服务客户，以更满的姿态迎接数字化转型新时代。
        </p>
        <!--<div class="try_btn" @click="goMobileHandle">立即试用</div>-->
        <div class="pic_box">
          <img :src="require('@/assets/images/pcHome/registerQR.jpg')" />
          <p>扫描二维码</p>
          <p>立即注册</p>
        </div>
      </div>
    </div>
    <footerNav v-if="isPC" @change-tab="changeTab" isHome />
    <div class="footer_box">
      <a href="tel:021-52813076" v-if="!isPC" class="tel_btn">咨询热线：021-5281 3076</a>
      <footerBox />
    </div>
    <div v-show="isPC" class="back_top_box">
      <div class="back_top">
        <div class="top">
          <div class="about_us">
            <div class="about_top">
              <img :src="require('@/assets/images/pcHome/registerQR.jpg')" />
              <div class="code_text">
                <p>扫描二维码</p>
                <p>立即注册</p>
              </div>
            </div>
            <p class="tel">咨询热线：021-5281 3076</p>
            <!--<el-button class="about_btn" @click="$router.push({ name: 'REGISTER' })">立即试用</el-button>-->
          </div>
        </div>
        <div class="bottom" @click="goAnchor('#home')"></div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import VueFaqAccordion from '@/components/VueFaqAccordion';
import HOMEHEADER from './HomeComponents/homeHeader.vue';
import planTabsDesc from './HomeComponents/planTabsDesc.vue';
import planPointComponent from './HomeComponents/planPointComponent.vue';
import featuresComponent from './HomeComponents/featuresComponent.vue';
import footerBox from './HomeComponents/footerBox.vue';
import footerNav from './HomeComponents/footerNav.vue';
import planJSON from './HomeComponents/PlanData.js';
import { WOW } from 'wowjs';
const PLANDATA = _.cloneDeep(planJSON);
const painRightImgs = planJSON.map(i => ({
  imgUrl: require(`@/assets/images/pcHome/${i.imgUrl}`),
}));
// 详情见源码，title实现是 v-html
PLANDATA.forEach(i => {
  i.titleFormat = `
  <span class="format_title point_title">${i.point.title}</span>
  <span class="format_title plan_title">${i.plan.title}</span>
`;
});

export default {
  components: {
    HOMEHEADER,
    planTabsDesc,
    planPointComponent,
    featuresComponent,
    footerBox,
    footerNav,
    VueFaqAccordion,
  },
  data () {
    return {
      videoUrl: 'https://api.yw.1xie.com/yxb-xc.mp4',
      PLANDATA,
      activeIndex: 0,
      painRightImgs,
      showBackTop: false,
      currentPageW: document.documentElement.clientWidth || document.body.clientWidth,
      isPC: true,
      activeName: 'first',
      isDrawerOpened: false,
      tabName: 'first_inner',
      timer: null,
      resetActiveIndex: 0,
    };
  },
  created () {
    const ua = navigator.userAgent;
    if (
      ua.toLowerCase().indexOf('android 11') > -1
      && ua.indexOf('Chrome') > -1
      && ua.toLowerCase().indexOf('vivobrowser') < 0
    ) {
      this.videoUrl = 'https://api.yxb.top/yxb-xc.webm';
    }
    window.addEventListener('resize', _.debounce(this.resizeHandle, 100), true);
    this.autoPlayHandle();
  },
  mounted () {
    // window.addEventListener('scroll', _.debounce(this.scrollWatch, 30));
    this.$nextTick(() => {
      if (this.$route.query?.id) {
        this.goAnchor(this.$route.query.id);
      }
      if (this.$route.query?.tabName) {
        this.tabName = this.$route.query?.tabName;
      }
      this.$refs.headerRef.resetActive(this.resetActiveIndex);
      new WOW({
        boxClass: 'wow', // default
        animateClass: 'animated', // default
        offset: 0, // default
        mobile: true, // default
        live: true, // default
      }).init();
    });
  },
  methods: {
    autoPlayHandle () {
      clearInterval(this.timer);
      const len = planJSON.length;
      this.timer = setInterval(() => {
        if (this.activeIndex < len - 1) {
          this.activeIndex++;
        } else {
          this.activeIndex = 0;
        }
      }, 5000);
    },
    itemSelect ({ itemIndex }) {
      this.activeIndex = +itemIndex;
      this.autoPlayHandle();
    },
    // scrollWatch () {
    //   const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
    //   this.showBackTop = scrollTop > 900;
    // },
    changeTab (tabName) {
      this.tabName = tabName;
    },
    closeVideo (openFlag) {
      if (this.$refs.videoRef) {
        if (openFlag) {
          this.$refs.videoRef.pause();
          this.$refs.videoRef.style.display = 'none';
          this.$refs.videoRef.style['z-index'] = '-1';
        } else {
          this.$refs.videoRef.style.display = 'block';
          this.$refs.videoRef.play();
        }
      }
    },
    goAnchor (id) {
      if (id.includes('home')) {
        this.resetActiveIndex = 0;
      } else if (id.includes('productSolutions')) {
        this.resetActiveIndex = 2;
      }
      this.$nextTick(() => {
        document.querySelector(id)?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      });
    },
    resizeHandle () {
      this.currentPageW = document.documentElement.clientWidth || document.body.clientWidth;
    },
    // 跳转H5端
    goMobileHandle () {
      const url = process.env.VUE_APP_BASE_URL + 'xiaozi-xmb/wireless/open/miniapp/redirectUrlLink';
      window.location.href = url;
    },
    handleDrawerOpened (isOpened) {
      this.isDrawerOpened = isOpened;
    },
  },
  beforeDestroy () {
    clearInterval(this.timer);
    window.removeEventListener('resize', this.resizeHandle, true);
    window.removeEventListener('scroll', this.scrollWatch);
  },
  watch: {
    currentPageW: {
      immediate: true,
      handler (val) {
        // 可视区域大于1200视为PC样式
        this.isPC = val > 1200;
      },
    },
  },
};
</script>
<style scoped src="./homePc.scss" lang="scss"></style>
