<template>
  <div class="planPointBox">
    <div class="swiper-container outer" id="second_swiper">
      <div class="swiper-wrapper outer">
        <div v-for="(item, index) in PLANDATA" :key="index" class="swiper-slide outer">
          <div class="plan_box">
            <div class="text point">
              <div class="title">{{ item.point.title }}</div>
              <p>{{ item.point.text }}</p>
            </div>
            <div class="text plan">
              <div class="title">{{ item.plan.title }}</div>
              <p>{{ item.plan.text }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>
<script>
import { cloneDeep } from 'lodash';
import Swiper from 'swiper';
import planJSON from './PlanData.js';
const PLANDATA = cloneDeep(planJSON);
export default {
  data () {
    return {
      PLANDATA,
      secondObj: null,
    };
  },
  mounted () {
    this.secondObj = null;
    // eslint-disable-next-line no-new
    this.$nextTick(() => {
      this.secondObj = new Swiper('#second_swiper', {
        slidesPerView: 'auto',
        spaceBetween: 15,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
        },
      });
    });
  },
  beforeDestroy () {
    this.secondObj = null;
  },
};
</script>
<style lang="scss" scoped>
.planPointBox {
  position: relative;
  top: 26px;
  height: 71.7vw;
  .swiper-container {
    height: 71.7vw;
    margin: auto;
    .swiper-wrapper.outer {
      display: flex;
      height: 100%;
      .swiper-slide.outer {
        display: flex;
        height: 100%;
        width: 83.6vw;
        flex-shrink: 0;
        .plan_box {
          position: relative;
          width: 100%;
          height: 100%;
          background: url('../../assets/images/mobileHome/planPointBg1.png') no-repeat;
          background-size: 100% 100%;
          .text {
            box-sizing: border-box;
            width: 68.5vw;
            color: #ffffff;
            .title {
              font-size: 15px;
              font-weight: 500;
              line-height: 16px;
              margin-bottom: 8px;
            }
            p {
              font-size: 12px;
              font-weight: 400;
              line-height: 21px;
            }
          }
          .point {
            padding: 26px 16px;
          }
          .plan {
            position: absolute;
            padding: 0 16px 18px 12px;
            bottom: 0;
            right: 0;
          }
        }
      }
    }
  }
}

@media (max-width: 370px) {
  .plan_box {
    background: url('../../assets/images/mobileHome/planPointBg2.png') no-repeat !important;
    background-size: 100% 100% !important;
    p {
      font-size: 12px;
      font-weight: 400;
      line-height: 17px !important;
    }
  }
}
</style>
