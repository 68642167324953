export default [
  {
    point: {
      title: '客户报修渠道单一',
      text: '客户只能基于传统电话的咨询/报修，影响效率，如何提升便捷性及多种选择？',
    },
    plan: {
      title: '客户便捷多样化报修',
      text: '可通过一键扫码报修和在线填写报修两种方式实现保修方式智能化。',
    },
    imgUrl: 'planRight1.png',
  },
  {
    point: {
      title: '服务流程难以管控',
      text: '服务跟踪难 ，服务效率低、服务体验差，怎样才能提升客户的服务体验？',
    },
    plan: {
      title: '服务流程透明',
      text: '通过手机端实时掌握工单服务状态，可视化管理服务流程，服务更透明',
    },
    imgUrl: 'planRight2.png',
  },
  {
    point: {
      title: '档案管理困难',
      text: '缺乏完整的客户和设备档案，遇到类似的问题，难以生成知识库，导致工作总重复，怎样才能改善？',
    },
    plan: {
      title: '服务档案有存储可查阅',
      text: '让每一次服务完整，让每一条记录清晰，为设备售后建立专属的生命周期档案。',
    },
    imgUrl: 'planRight3.png',
  },
  {
    point: {
      title: '维修备件难管理',
      text: '备件管理数据多，型号复杂容易错，出入库记录不完整，怎样才能做好备件的管理？',
    },
    plan: {
      title: '维修备件出入记录管理',
      text: '一键申请个人备件库，实现灵活申领、快捷使用，库存管理更加轻松。',
    },
    imgUrl: 'planRight4.png',
  },
  {
    point: {
      title: '客户管理不精细',
      text: '客户信息无法管理，多个信息缺乏关联，售后市场难以挖掘，怎样才能对客户做到精细化管理？',
    },
    plan: {
      title: '客户个性化精细管理',
      text: '根据不同客户需求，生成不同自定义工单、自定义工作流等模板，让客户-工单-服务更加精细化。',
    },
    imgUrl: 'planRight5.png',
  },
  {
    point: {
      title: '数据零散分析乱',
      text: '平台信息难以聚集，制作报表焦头烂额，内部分析数据不全，怎样才能有效的数据运营？',
    },
    plan: {
      title: '聚合数据大屏',
      text: '数字大屏展示，轻松实现自动化分析，帮助企业更有效的数据管理及运营。',
    },
    imgUrl: 'planRight6.png',
  },
];
